import React, { useEffect, useState, useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ResultsPopUp  from "./ResultsPopUp";
import { useSound } from 'SoundContext';
import soundFile from 'assets/sound/npc_fight.mp3'
import { useError } from 'ErrorProvider';
import { FaQuestionCircle } from 'react-icons/fa';

import {refreshAll, anchorGetReports, anchorChallengeNPC} from "BlockchainInteractionWax";

const LeftContent = ({ ual }) => {

  const [results, setResults] = useState([
    { id: 0, attacker: '', defender: '', winner: '', event_log: [] }
  ]);
  const [isOpenResults, setIsOpenResults] = useState(false)
  const [selectedLog, setSelectedLog] = useState([]);
  const [selectedOpponent, setSelectedOpponent] = useState(0)

  const { isMuted } = useSound();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  const fetchData = useCallback(async () => {
    if (ual.activeUser && store)
    {
        const allResults = await anchorGetReports(ual)
        setResults(allResults)
    }
}, [ual, store]); 

  useEffect(() => {
    fetchData();
  }, [store, ual]);

  async function challengeNPC(index, type) {

  const bet = [];

  if(index !== 0)
    {
      if(type === 0)
        {
          bet.push(3);
          bet.push(0);
        }
        else
        {
          bet.push(0);
          bet.push(2);
        }
    }
  else
    {
      bet.push(0);
      bet.push(0);
    }
  

  await anchorChallengeNPC(ual, index, bet, setError)
  if (!isMuted) {
      const audio = new Audio(soundFile);
      audio.play().catch((error) => {
      console.error('Audio playback failed:', error);
      });
  }
  await new Promise(resolve => setTimeout(resolve, 3000));
  await refreshAll(dispatch);
  }

  return (
    <div className="flex flex-col items-center space-y-4">
      
      {/* Reports Scroll Area */}
      <div className="w-80 h-[42vh] overflow-y-scroll bg-[#2F2F2E] bg-opacity-80 rounded-md p-2">
        <span className="text-white text-xl text-center font-extrabold shadow-lg mb-2 block">Reports</span>
        {results
          .sort((a, b) => b.id - a.id) // Sort by id in descending order
          .map(({ id, attacker, defender, winner, event_log }, index) => (
            <div
              key={id || index}
              className={`flex items-center justify-between p-2 text-white border-b border-gray-600 last:border-b-0 ${
                ual.activeUser?.accountName && winner === ual.activeUser.accountName ? 'bg-green-700' : 'bg-red-700'
              }`}
            >
              <span className="text-white text-sm">
                {ual.activeUser?.accountName === attacker ? defender : attacker} Fight: {id}
              </span>
  
              <button
                className="bg-[#29c4cc] bg-opacity-80 text-white rounded-md px-3 py-1 w-24 text-sm"
                onClick={() => {
                  setSelectedLog(event_log); // Set the selected event log
                  setIsOpenResults(true);    // Open the results popup
                }}
              >
                Results
              </button>
            </div>
          ))}
        <ResultsPopUp isOpen={isOpenResults} setIsOpen={setIsOpenResults} ual={ual} log={selectedLog} />
      </div>
  
      {/* Opponents Scroll Area */}
      <div className="w-80 h-[32vh] overflow-y-scroll bg-[#2F2F2E] bg-opacity-80 rounded-md p-2">
        <span className="text-white text-xl text-center font-extrabold shadow-lg mb-2 block">NPC</span>
        {["Anonymous", "Tiny", "Leslie", "Duke", "Ma'ol"].map((playerName, index) => (
          <div key={index} className="flex items-center justify-between p-2 text-white border-b border-gray-600 last:border-b-0">
            <span className="text-white">{playerName}</span>
            <div className="flex space-x-2">
              <button
                className="bg-[#25a11a] bg-opacity-80 text-white rounded-md px-3 py-1 w-20 text-sm"
                onClick={() => {
                  challengeNPC(index, 0);
                }}
              >
                {index === 0 ? "FREE" : "HDC"}
              </button>
              <button
                className="bg-[#1a75a1] bg-opacity-80 text-white rounded-md px-3 py-1 w-20 text-sm"
                onClick={() => {
                  challengeNPC(index, 1);
                }}
              >
                {index === 0 ? "FREE" : "WAX"}
              </button>
            </div>
          </div>
        ))}
<div className="flex justify-center mb-2">
  <div className="flex items-center relative group">
    <FaQuestionCircle className="text-white text-lg cursor-pointer" />

    {/* Tooltip positioned above and slightly to the left */}
    <div className="absolute bottom-full mb-2 left-[-50px] w-48 p-2 bg-gray-800 text-white text-xs rounded-md shadow-lg opacity-0 pointer-events-none group-hover:opacity-100 transition-opacity duration-300 z-10">
      Pay a fee of 3 HDC or 2 WAX <br />
      to challenge your opponent. <br />
      If you win, you can win WAX <br />
      and other goodies.
    </div>
  </div>
</div>
      </div>
    </div>
  );
  
};



export default LeftContent;
