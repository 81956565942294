import React from "react";
import { FaTimes } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import icon2 from "assets/images/icons/clean-cash.png";
import icon3 from "assets/images/icons/hst-final.png";
import icon4 from "assets/images/icons/slot.png";
import icon1 from "assets/images/icons/hustler-dirty-cash-token.png";
import Modal from "components/shared/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useError } from 'ErrorProvider';

import { refreshAll,  unlockSecondSafe } from "BlockchainInteractionWax";

const AddForm = ({ isOpen, setIsOpen, ual }) => {
  const dispatch = useDispatch();
  const { setError } = useError();

  const data = [
    {
      id: uuid(),
      icon: icon1,
      cost: "1200",
    },
    {
      id: uuid(),
      icon: icon2,
      cost: "500",
    },
    {
      id: uuid(),
      icon: icon3,
      cost: "300",
    },
    {
      id: uuid(),
      icon: icon4,
      cost: "250",
    },
  ];
  async function unlockSecondSafeClick()
  {
    await unlockSecondSafe(ual, setError)
    setIsOpen(false)
    await refreshAll(dispatch);
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <div
        className="w-full rounded-xlpy-4 px-6 font-Montserrat"
        id="addslot_modal"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <div>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-white">
              Slot Upgrade Cost
            </h2>
            <FaTimes
              className="block text-2xl text-white hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="flex justify-center">
            <div>
              {data.map((item) => (
                <div
                  className="addslot-item flex items-center gap-x-3"
                  key={Math.random()}
                >
                  <div className="w-12">
                    <img
                      src={item.icon}
                      className="h-full w-full object-contain"
                      alt="Token Icon"
                    />
                  </div>
                  <p className="font-bold text-white">{item.cost}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5 flex justify-center">
            <div className='buttonContainer '  >
              <button className="w-32 py-1 px-3" onClick={() => unlockSecondSafeClick()}>
                Add Slot
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddForm;
