import Tippy from "@tippyjs/react";
import { FaInfoCircle } from "react-icons/fa";
import hustleToken from "assets/images/icons/hst-final.png";
import { FaPlus } from "react-icons/fa";

import React, { useState, useEffect, useCallback, useMemo, Component } from "react";
import { isSafeStacked,  unstakeSafe, startMiningSafe, refreshAll, anchorGetGRank, getMiningPowerSafe,
  isSafeBoosterStacked, setNextPhase, claimSafe } from "BlockchainInteractionWax";
import { useDispatch, useSelector } from "react-redux";
import AddBoost from "./AddBoost";
import AddCharacter from "./AddCharacter";
import MaintenanceForm from "./MaintenanceForm";
import { useError } from 'ErrorProvider';

const Safe = ({ ual }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isBoostOpen, setIsBoostOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCharacterOpen, setIsCharacterOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [assetId, setAssedId] = useState(0)
  const [templateId, setTemplateId] = useState(0)
  const [lastClaimed, setLastClaimed] = useState(0)
  const [isMining, setIsMining] = useState(0)
  const [phase, setPhase] = useState(0)
  const [maintenance, setMaitenance] = useState(0)
  const [gRank, setGRank] = useState(0)
  const [remainingTime, setRemainingTime] = useState(0)
  const [htnMined, setTotalHtnMined] = useState(0)
  const [bonus, setBonus] = useState(0.0)
  const [minMain, setMinMain] = useState(0)
  const [maxMain, setMaxMain] = useState(0)

  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  useEffect(() => {
    const fetchData = async () => {
      if (ual.activeUser !== undefined) {
        const data = await isSafeStacked(ual, 1);
        setImageSrc(data[0]);
        setAssedId(data[1]);
        setLastClaimed(data[2]);
        setIsMining(data[3]);
        setPhase(data[4]);
        setMaitenance(data[5])
        const rank = await anchorGetGRank(ual)
        setGRank(rank)

        const bonusValue = await isSafeBoosterStacked(ual)
        setBonus(bonusValue)
        let value = []
        if(data[6] !== undefined)
        {
          value = await getMiningPowerSafe(ual, data[6])
        }
        setMinMain(value[3])
        setMaxMain(value[4])

        if(data[3] === 1)
        {
          const current_time = Math.floor(Date.now() / 1000)
          setRemainingTime(current_time - data[2])          

          if(data[4] === 0 && current_time - data[2] > 60 ) 
          {
            setTotalHtnMined((21600*value[0]).toFixed(2) * bonusValue[3])
          }
          if(data[4] === 1 ) 
          {           
            const totalMined = (Math.min(43200, current_time - data[2] - 21600)*value[1]).toFixed(2) * bonusValue[3] + (21600*value[0]).toFixed(2) * bonusValue[3]
            setTotalHtnMined(totalMined.toFixed(2))
          }
          if(data[4] === 2 ) 
          {          
            const totalMined =  (Math.min(108000, current_time - data[2] - 64800)*value[2]).toFixed(2) * bonusValue[3] + (21600*value[0]).toFixed(2) * bonusValue[3] + (43200*value[1]).toFixed(2) * bonusValue[3] 
            setTotalHtnMined(totalMined.toFixed(2))
          }
        }        
      }
    };
    fetchData();
  }, [store, ual.activeUser]);

  async function handleClick()
  {
    if(imageSrc === "")
    {
      setIsCharacterOpen(true)
      await refreshAll(dispatch);
    }
  };

  async function claimSafeClick()
  {
    const ids = [assetId]
    await claimSafe(ual, ids, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function performMaintenance()
  {
    setIsOpen(true)    
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function startNextPhase()
  {
    await setNextPhase(ual, assetId, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function startMiningSafeClick()
  {
    await startMiningSafe(ual, assetId, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function UnstakeClick()
  {
    await unstakeSafe(ual, assetId, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  const timerStyle = {
    position: "absolute",
    top: "50%", // Center the timer vertically
    left: "50%", // Center the timer horizontally
    transform: "translate(-50%, -50%)", // Centering transformation
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "14px",
  };

    // Function to format time in HH:MM:SS
    const formatTime = (timeInSeconds) => {
      if (timeInSeconds > 21600 && phase === 0) {
        return "6:00:00";
      }

      if (timeInSeconds > 64800 && phase === 1) {
        return "18:00:00";
      }

      if (timeInSeconds > 172800 && phase === 2) {
        return "48:00:00";
      }
  
      const hours = Math.floor(timeInSeconds / 3600);
      const minutes = Math.floor((timeInSeconds % 3600) / 60);
      const seconds = timeInSeconds % 60;
  
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };
  
    useEffect(() => {
      if ( remainingTime > 0) {
        const intervalId = setInterval(() => {
          setRemainingTime((prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime + 1 : 0
          );
        }, 1000);
  
        return () => clearInterval(intervalId);
      }
    }, [remainingTime]);

  return (    
    <div className="mx-4 box-border pb-4">
  {gRank >= 100 && (     
    <div className="custom-shadow mr-2 box-border h-80 overflow-hidden rounded-md border border-[#2F2F2E] p-6 text-center">
      <div
        className="relative h-full w-full cursor-pointer items-center justify-center rounded-xl px-2"
        onClick={handleClick}
      >
        {imageSrc !== "" ? (
          <div className="item-container relative h-full w-full flex items-center justify-center">
            <img
              src={imageSrc}
              alt="your-image-description"
              className="object-contain max-h-full max-w-full mx-auto"
            />
            {remainingTime > 0 && (
              <div className="cooldown-timer" style={timerStyle}>
                Time elapsed: {formatTime(remainingTime)}
              </div>
            )}
          </div>
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <FaPlus className="text-5xl text-gray-500" />
          </div>
        )}
      </div>
    </div>
  )}
      <AddBoost setIsOpen={setIsBoostOpen} isOpen={isBoostOpen} ual={ual} />
      <AddCharacter setIsOpen={setIsCharacterOpen} isOpen={isCharacterOpen} ual={ual} slot={1}/>
      <MaintenanceForm setIsOpen={setIsOpen} isOpen={isOpen} ual={ual} minMain={minMain} maxMain={maxMain} assetId={assetId}/>

      <div className="mt-2">
          {maintenance === "1" && isMining === 0 &&(
            <div className="w-12/12 mx-auto mt-2 ">
              <div className="flex justify-center">
                <div className='buttonContainer '  >
                  <button className="w-32 py-1 px-3" onClick={() => performMaintenance()}>
                  Perform Maintenance
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mt-2">
          {isMining === 0 && maintenance !== "1" &&(
            <div className="w-12/12 mx-auto mt-2 ">
              <div className="flex justify-center">
                <div className='buttonContainer '  >
                  <button className="w-32 py-1 px-3" onClick={() => startMiningSafeClick()}>
                  Start Mining
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mt-2">
          {isMining === 1 && phase === 0 && remainingTime > 21600 &&(
            <div className="w-12/12 mx-auto mt-2 ">
              <div className="flex justify-center">
                <div className='buttonContainer '  >
                  <button className="w-32 py-1 px-3" onClick={() => startNextPhase()}>
                    Next Phase
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mt-2">
          {isMining === 1 && phase === 1 && remainingTime > 64800 &&( 
            <div className="w-12/12 mx-auto mt-2 ">
              <div className="flex justify-center">
                <div className='buttonContainer '  >
                  <button className="w-32 py-1 px-3" onClick={() => startNextPhase()}>
                    Next Phase
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mt-2">
          {isMining === 1 && ((phase === 0 && remainingTime > 21600) || (phase > 0 && remainingTime > 21600)) &&(
            <div className="w-12/12 mx-auto mt-2 ">
              <div className="flex justify-center">
                <div className='buttonContainer '  >
                  <button className="w-32 py-1 px-3" onClick={() => claimSafeClick()}>
                    Claim
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {isMining === 1 && ((phase === 0 && remainingTime > 21600) || (phase > 0 && remainingTime > 21600)) &&(
        <div className="flex items-center justify-center">
          <div className="w-12 my-3">
            <img
              src={hustleToken}
              alt="Hustlers Token"
              className="h-full w-full opacity-100" 
            />
          </div>         
          <p className="text-white font-bold">{htnMined}</p> 
        </div>
      )}

        <div className="mt-2">   
        {imageSrc !== "" && (       
            <div className="w-12/12 mx-auto mt-2 ">
              <div className="flex justify-center">
                <div className='buttonContainer '  >
                  <button className="w-32 py-1 px-3" onClick={() => UnstakeClick()}>
                  Unstake
                  </button>
                </div>
              </div>
            </div>
            )}
        </div>
    </div>
  );
};

export default Safe;
