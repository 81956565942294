import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import icon1 from "assets/images/icons/solo-g.png";
import { useError } from 'ErrorProvider';

import { anchorGetNFTbyType} from "BlockchainInteractionWax";

const SeeItemStats = ({ isOpen, setIsOpen, ual }) => {
  const [listData, setListData] = useState([]);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  useEffect(() => {
    const fetchData = async () => {
      if (ual.activeUser !== undefined) {
        const nftData = await anchorGetNFTbyType(ual, "all")        
        setListData(nftData);
      }
    };
    fetchData();
  }, [store, ual]);

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={(event) => { event.stopPropagation(); setIsOpen(false) }}>
        <div className="w-full bg-[#1a1b1f] border rounded-xl border-[#252525] py-4 px-6" style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-olive">Item list</h2>
            <FaTimes
              className="block text-2xl text-olive hover:cursor-pointer"
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(false);
              }}
            />
          </div>
          <div>
            {listData.length === 0 && (
              <div className="h-60 flex flex-col w-full h-full items-center justify-center">
                <div className="mt-3 flex flex-col items-center justify-center gap-x-2">
                  <div className="w-20 gap-y-2 mb-4">
                    <img src={icon1} alt="Solo-G" className="object- h-full" />
                  </div>
                  <h1 className="px-5 py-2 text-center font-Montserrat text-md font-bold text-olive">
                    No items staked. <br />
                    Acquire an eligible NFT.
                  </h1>
                </div>
              </div>
            )}
            {listData.length > 0 && (
              <ul className="h-80 overflow-y-auto w-full space-y-2">
                {listData.map((item) => (
                  <li key={item.asset_id} className="flex items-center justify-between p-2 border-b-2 border-gray-500">
                    {/* Image Section */}
                    <div className="relative flex items-center" style={{ width: '25%' }}>
                      <img
                        src={`https://ipfs.io/ipfs/${item.image}`}
                        alt="Decoded image"
                        className="mr-2 w-full h-auto rounded-md"
                      />
                    </div>

                    {/* Name and Stats Section */}
                    <div className="flex flex-col flex-wrap text-white space-y-2 ml-2" style={{ width: '60%' }}>
                      {/* Item Name */}
                      <span className="font-bold text-lg">{item.name}</span>

                      {/* Stats (aligned in a grid) */}
                      <div className="grid grid-cols-2 gap-y-1 gap-x-4">
                        {item.power !== 0 && (
                          <>
                            <span className="text-sm">Power:</span>
                            <span className="text-sm">{parseFloat(item.power).toFixed(2)}</span>
                          </>
                        )}
                        {item.defense !== 0 && (
                          <>
                            <span className="text-sm">Defense:</span>
                            <span className="text-sm">{parseFloat(item.defense).toFixed(2)}</span>
                          </>
                        )}
                        {item.hp !== 0 && (
                          <>
                            <span className="text-sm">HP:</span>
                            <span className="text-sm">{parseFloat(item.hp).toFixed(2)}</span>
                          </>
                        )}
                        {item.accuracy !== 0 && (
                          <>
                            <span className="text-sm">Accuracy:</span>
                            <span className="text-sm">{parseFloat(item.accuracy).toFixed(2)}</span>
                          </>
                        )}
                        {item.evasion !== 0 && (
                          <>
                            <span className="text-sm">Evasion:</span>
                            <span className="text-sm">{parseFloat(item.evasion).toFixed(2)}</span>
                          </>
                        )}
                        {item.dodge !== 0 && (
                          <>
                            <span className="text-sm">Dodge:</span>
                            <span className="text-sm">{parseFloat(item.dodge).toFixed(2)}</span>
                          </>
                        )}
                        {item.crit !== 0 && (
                          <>
                            <span className="text-sm">Crit:</span>
                            <span className="text-sm">{parseFloat(item.crit).toFixed(2)}</span>
                          </>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SeeItemStats;
