import React, { useState, useEffect, useCallback, useMemo, Component } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";

import { useDispatch, useSelector } from "react-redux";
import { anchorAddPoints, refreshAll} from "BlockchainInteractionWax.js"
import { useError } from 'ErrorProvider';

const SetPointsPopUp = ({ isOpen, setIsOpen, ual, points, asset_id }) => {
  const [strength, setStrength] = useState("0");
  const [vitality, setVitality] = useState("0");
  const [dexterity, setDexterity] = useState("0");
  const [luck, setLuck] = useState("0");

  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  const fetchData = useCallback(async () => {
    if (ual.activeUser && store) {

    }
  }, [ual, store]); 

  useEffect(() => {
    fetchData();
  }, [fetchData, store]);

  async function handleSetStatsClick()
  {
    const strengthNumber = Number(strength);
    const vitalityNumber = Number(vitality);
    const dexterityNumber = Number(dexterity);
    const luckNumber = Number(luck);
    let stats = []
    stats.push(strengthNumber)
    stats.push(luckNumber)
    stats.push(dexterityNumber)
    stats.push(vitalityNumber)

    await anchorAddPoints(ual, asset_id, stats, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch)
    setIsOpen(false);    
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <div
        className="w-full rounded-xl py-4 px-6 font-Montserrat py-4 px-6"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <form>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-white">
              Add Stats
            </h2>
            <FaTimes
              className="block text-2xl text-white hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="text-center text-white"></div>
          <div>
          <div className=" flex items-center justify-end gap-x-2 text-white">              
            </div>
            <div className=" flex items-center justify-end gap-x-2 text-white">              
              <p className="mt-2">Total points available: {points}</p>
              
            </div>
            <div className=" flex items-center justify-end gap-x-2 text-white">              
            </div>
            <div className="grid grid-cols-2 gap-4 justify-center">
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Strength:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={strength}
                  onChange={(event) => setStrength(event.target.value)}
                  placeholder="Enter how much do add to stat"
                  step="1"
                  min="1"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Vitality:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={vitality}
                  onChange={(event) => setVitality(event.target.value)}
                  placeholder="Enter how much do add to stat"
                  step="1"
                  min="1"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Dexterity:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={dexterity}
                  onChange={(event) => setDexterity(event.target.value)}
                  placeholder="Enter how much do add to stat"
                  step="1"
                  min="1"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Luck:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={luck}
                  onChange={(event) => setLuck(event.target.value)}
                  placeholder="Enter how much do add to stat"
                  step="1"
                  min="1"
                />
              </div>
            </div>
          </div>
        </div>

          <div className="mt-5 flex justify-center">
          <div className='buttonContainer'>
            <button className="w-40 py-1 px-3"
            onClick={(e) => {e.preventDefault(); handleSetStatsClick()}}>Add Stats</button>
            /</div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SetPointsPopUp;
