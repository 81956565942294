import React, { useState, useEffect, useCallback } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";
import Stats from "./Stats";
import UpgradeForm from "./UpgradeForm";
import { unstakeConsignment, getUpgradeCostsForGhost, anchortGetNftInformation, refreshAll } from "BlockchainInteractionWax.js"
import { useDispatch, useSelector } from "react-redux";
import { useError } from 'ErrorProvider';

const ModalMenu = ({ isOpenPopUp, setIsOpenPopUp, assetId, ual }) => {
  const [isUpGradeOpen, setIsUpgradeOpen] = useState(false);
  const [isStatsOpen, setIsStatsOpen] = useState(false);
  const [nftData, setNftData] = useState([{ name: "Default Name", level: 0, rarity: "Unknown", type: "Unknown", edition: "Unknown" }]);
  const [upgradeCost, setUpgradeCost] = useState({
    clean_cash: 5,
    dirty_cash: 5,
    hustler_token: 5,
    upgrade_token: 5
  });
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  async function handleUnstakeConsignment(assetId, ual) {
    await unstakeConsignment(assetId, ual, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    refreshAll(dispatch)
    setIsOpenPopUp(false);
  }


  const fetchData = useCallback(async () => {
    if (ual.activeUser) {
      const upgradeCost = await (getUpgradeCostsForGhost(ual, assetId));
      setUpgradeCost(upgradeCost)
      const value = await anchortGetNftInformation(ual, assetId)
      setNftData(value)
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [store]);

  return (
    <>
      <Modal isOpen={isOpenPopUp} onRequestClose={() => setIsOpenPopUp(false)}>
        <div
          className="w-full rounded-xl py-4 px-6"
          style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
        >
          <div>
            <div className="mb-2 flex items-center justify-between">
              <div />
              <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-olive">
                {nftData.name} {nftData.level}
              </h2>
              <FaTimes
                className="block text-2xl text-olive hover:cursor-pointer"
                onClick={() => setIsOpenPopUp(false)}
              />
            </div>
            <div className="text-center">
              <div className="my-5">
                <div className='buttonContainer my-5'  >
                  <button className="w-full px-4 py-1 " onClick={() => setIsUpgradeOpen(true)} >Upgrade</button>
                </div>
              </div>
              <div className='buttonContainer my-5'  >
                <button className="w-full px-4 py-1 " onClick={() => setIsStatsOpen(true)} >Stats</button>
              </div>
              <div className='buttonContainer my-5'  >
                <button className="w-full px-4 py-1 " onClick={() => { handleUnstakeConsignment(assetId, ual); }}>Unstake</button>
              </div>

            </div>
          </div>
        </div>
      </Modal>
      {<UpgradeForm
        isOpen={isUpGradeOpen}
        setIsOpen={setIsUpgradeOpen}
        upgradeCost={upgradeCost}
        ual={ual}
        assetId={assetId}
      />}
      <Stats isOpen={isStatsOpen} setIsOpen={setIsStatsOpen} ual={ual} assetId={assetId} />
    </>
  );
};

export default ModalMenu;
