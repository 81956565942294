import React, { useEffect, useState, useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import {refreshAll, anchorGetUsedPvpHustler, buyXP, levelUpBattleXp} from "BlockchainInteractionWax";
import AddCharacterStats from "./AddCharacterStats"
import AddItemStats from "./AddItemStats"
import SeeItemStats from "./SeeItemStats"
import AddWaxPopup from "./AddWaxPopup"
import WithdrawWaxPopup from "./WithdrawWaxPopup"
import SetPointsPopUp from "./SetPointsPopUp"
import ResetStatsPopUp from "./ResetStatsPopUp";
import { asset_id } from "components/Hustle/Consignment/Content/LeftContent";
import { useError } from 'ErrorProvider';
import { FaQuestionCircle } from 'react-icons/fa';

const LeftContent = ({ ual }) => {

  const [isOpenAddStats, setIsOpenAddStats] = useState(false);
  const [isOpenAddStatsItem, setIsOpenAddStatsItem] = useState(false);
  const [isOpenSeeItems, setIsOpenSeeItems] = useState(false);
  const [isOpenAddWax, setIsOpenAddWax] = useState(false);
  const [isOpenWithdrawWax, setIsOpenWithdrawWax] = useState(false)
  const [isSetPointsOpen, setIsSetPointsOpen] = useState(false)
  const [isOpenResetStats, setIsOpenResetStats] = useState(false)
  const [pvpStats, setPvpStats] = useState([]);
  const [xpPercentage, setXpPercentage] = useState(0)
  const [experienceThisLevel, setExperienceThisLevel] = useState(0)
  const [totalXpNeeded, setTotalXpNeeded] = useState(0)

  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  const fetchData = async () => {
  if (ual.activeUser !== undefined) {
    const value = await anchorGetUsedPvpHustler(ual)

    //Compute Xp
    setPvpStats(value)
    let total_xp_needed = 0    
    let i = 0
    for(i = -1; i <= value.level; i++)
      {
        total_xp_needed += 200 + i * 200;
      }      

    setXpPercentage( value.experience/total_xp_needed*100)
    setExperienceThisLevel(value.experience)
    setTotalXpNeeded(total_xp_needed)
    
  }
};

useEffect(() => {
  fetchData();
}, [store, ual.activeUser]);

  async function handleUnstake() {
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function BuyXp(times) {
    await buyXP(ual, pvpStats.asset_id, times, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function LevelUp() {
    await levelUpBattleXp(ual, pvpStats.asset_id, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  return (
<div className="lg:w-1/4"> {/* Parent div to wrap both sections */}
  {pvpStats.level !== 0 && (
    <div className="buttonContainer w-full h-full pt-8 px-4 mb-8 relative">
      
      {/* Centered Question Mark Icon */}
      <div className="flex justify-center mb-2">
        <div className="flex items-center group relative">
          <FaQuestionCircle className="text-white text-lg cursor-pointer" />
          <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 w-48 p-2 bg-gray-800 text-white text-xs rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
            Strength increases power and defense.
            <br />
            Vitality increases HP.
            <br />
            Dexterity increases accuracy, evasion, and dodge.
            <br />
            Luck increases critical strike and dodge.
          </div>
        </div>
      </div>

      {/* Hustler Attributes Header */}
      <h3 className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-center font-Montserrat text-xl font-bold text-olive mb-4">
        Hustler Attributes
      </h3>
  
      {/* Table with Stats */}
      <table className="w-full border border-[#2F2F2E] text-center p-1">
        <thead>
          <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md">
            <th className="px-4 py-2 font-semibold">Stat</th>
            <th className="px-4 py-2 font-semibold">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
            <td className="px-4 py-2">Strength</td>
            <td className="px-4 py-2">{parseFloat(pvpStats.strength).toFixed(0)}</td>
          </tr>
          <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
            <td className="px-4 py-2">Vitality</td>
            <td className="px-4 py-2">{parseFloat(pvpStats.vitality).toFixed(0)}</td>
          </tr>
          <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
            <td className="px-4 py-2">Dexterity</td>
            <td className="px-4 py-2">{parseFloat(pvpStats.dexterity).toFixed(0)}</td>
          </tr>
          <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
            <td className="px-4 py-2">Luck</td>
            <td className="px-4 py-2">{parseFloat(pvpStats.luck).toFixed(0)}</td>
          </tr>
          <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
            <td className="px-4 py-2">Experience</td>
            <td className="px-4 py-2">
              <div className="w-full h-4 bg-gray-700 rounded-md overflow-hidden">
                <div
                  className="h-full bg-green-700"
                  style={{
                    width: `${xpPercentage}%`
                  }}
                ></div>
              </div>
              <span className="text-sm text-white">{experienceThisLevel}/{totalXpNeeded}</span>
            </td>
          </tr>
          <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
            <td className="px-4 py-2">Level</td>
            <td className="px-4 py-2">{parseFloat(pvpStats.level).toFixed(0)}</td>
          </tr>
          {pvpStats.points > 0 && (
            <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
              <td className="px-4 py-2">Points</td>
              <td className="px-4 py-2">{parseFloat(pvpStats.points).toFixed(0)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )}

  {/* Buttons Container */}
  <div className="flex flex-wrap justify-center gap-x-4 gap-y-2 mt-4">
    <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => setIsOpenAddStats(true)}>
      Set Hustler Stats
    </button>
    <AddCharacterStats isOpen={isOpenAddStats} setIsOpen={setIsOpenAddStats} ual={ual} />

    <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => setIsOpenAddStatsItem(true)}>
      Set Item Stats
    </button>
    <AddItemStats isOpen={isOpenAddStatsItem} setIsOpen={setIsOpenAddStatsItem} ual={ual} />

    <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => setIsOpenAddWax(true)}>
      Add WAX
    </button>
    <AddWaxPopup isOpen={isOpenAddWax} setIsOpen={setIsOpenAddWax} ual={ual} />

    <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => setIsOpenWithdrawWax(true)}>
      Withdraw WAX
    </button>
    <WithdrawWaxPopup isOpen={isOpenWithdrawWax} setIsOpen={setIsOpenWithdrawWax} ual={ual} />

    <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => setIsOpenSeeItems(true)}>
      See all equipment
    </button>
    <SeeItemStats isOpen={isOpenSeeItems} setIsOpen={setIsOpenSeeItems} ual={ual} />

    {pvpStats.level !== 0 && (
    <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => setIsOpenResetStats(true)}>
      Reset stats
    </button>
    )}
    <ResetStatsPopUp isOpen={isOpenResetStats} setIsOpen={setIsOpenResetStats} ual={ual} asset_id={pvpStats.asset_id} resets={pvpStats.resets} />

    {pvpStats.level !== 0 && (
    <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => BuyXp(1)}>
      Train
    </button>
    )}

    {pvpStats.level !== 0 && (
    <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => BuyXp(5)}>
      Train x5
    </button>
    )}

    {pvpStats.experience >= totalXpNeeded && (
      <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => LevelUp()}>
        Level Up
      </button>
    )}

    {pvpStats.points > 0 && (
      <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => setIsSetPointsOpen(true)}>
        Set Points
      </button>
    )}
    <SetPointsPopUp isOpen={isSetPointsOpen} setIsOpen={setIsSetPointsOpen} ual={ual} points={pvpStats.points} asset_id={pvpStats.asset_id} />
  </div>
</div>
  );
};



export default LeftContent;
