import React, { useState, useEffect } from "react";
import HustleMenu from "./HustleMenu";
import SafehouseMenu from "./SafehouseMenu";
import MenuItem from "./MenuItem";
import ShopMenu from "./ShopMenu";
import Postoffice from "./Postoffice";
import CleanCashImg from "assets/images/icons/clean-cash.png";
import SlotImg from "assets/images/icons/slot.png";
import FinalImg from "assets/images/icons/hst-final.png";
import DirtyImg from "assets/images/icons/hustler-dirty-cash-token.png";
import GangWarsMenu from "./GangWarsMenu"

import { anchorGetBalance } from "BlockchainInteractionWax";
import { useSelector } from "react-redux";

const Menu = ({ handleCloseDrawer, ual }) => {
  const [cleanCash, setCleanCash] = useState(0);
  const [slot, setSlot] = useState(0);
  const [final, setFinal] = useState(0);
  const [dirty, setDirty] = useState(0);
  const store = useSelector((state) => state);

  const fetchData = async () => {
    
    if (ual.activeUser) {
      const values = await anchorGetBalance(ual);
      setCleanCash(parseFloat(values[0]).toFixed(3));
      setSlot(parseFloat(values[4]).toFixed(0));
      setFinal(parseFloat(values[2]).toFixed(3));
      setDirty(parseFloat(values[1]).toFixed(3));
    }
  };

  useEffect(() => {
    fetchData();
  }, [store, fetchData]);

  return (
    <div className="pl-4 lg:hidden items-start overflow-x-hidden">
      <div className="flex flex-wrap justify-evenly xl:gap-5">
        <div className="flex flex-col items-center justify-around w-1/4">
          <img alt="" src={CleanCashImg} className="w-20" />
          <p className="text-white text-xs">{cleanCash}</p>
        </div>
        <div className="flex flex-col items-center justify-around w-1/4">
          <img alt="" src={SlotImg} className="w-8" />
          <p className="text-white text-xs">{slot}</p>
        </div>
        <div className="flex flex-col items-center justify-around w-1/4">
          <img alt="" src={FinalImg} className="w-8" />
          <p className="text-white text-xs">{final}</p>
        </div>
        <div className="flex flex-col items-center justify-around w-1/4">
          <img alt="" src={DirtyImg} className="w-8" />
          <p className="text-white text-xs">{dirty}</p>
        </div>
      </div>

      <ul className="mt-4">
        <GangWarsMenu onClick={handleCloseDrawer} />
        <div className="my-3" />
        <SafehouseMenu handleCloseDrawer={handleCloseDrawer} />
        <div className="my-3" />
        <HustleMenu handleCloseDrawer={handleCloseDrawer} />
        <div className="my-3" />
        <Postoffice handleCloseDrawer={handleCloseDrawer} />
        <div className="my-3" />
        <MenuItem text={"DZ"} url="/dropzone" onClick={handleCloseDrawer} />
        <ShopMenu handleCloseDrawer={handleCloseDrawer} />
        <MenuItem text={"Stash"} url="/stash" onClick={handleCloseDrawer} />
        {/* <MenuItem text={"Hustlers Club"} url="/mission" onClick={handleCloseDrawer} /> */}
      </ul>
    </div>
  );
};

export default Menu;
