import { styled, TextField } from "@mui/material";
import React, { useState, useEffect, Component, useCallback } from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import { useDispatch } from "react-redux";
import 'tippy.js/dist/tippy.css';

import { anchorGetGRank, getLastActionMessage, anchorGetMPower, anchorGetMLCleanCashe, anchorGetuserinfors, anchorGetImageId, setUsername,
   getUsername, refreshAll, anchorGetWorldStats, anchorGetWorldStatsP, checkInBlockchain, getTimeCheckIn } from "BlockchainInteractionWax";

import { FaInfoCircle, FaPlus } from "react-icons/fa";
import ProfileForm from 'components/Profile/profileForm';
import Safehouse from "assets/images/safehouse.jpg";
import { check } from "prettier";
import { useError } from 'ErrorProvider';

const Profile = ({ ual }) => {
  const [gRank, setGRank] = useState(0);
  const [StopSearch, setStopSearch] = useState(0);
  const [sucessfulDrop, setSucessfulDrop] = useState(0);
  const [sucessfulDropp, setSucessfulDropp] = useState(0);
  const [shootout, setShootout] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imageId, setImageId] = useState("");
  const [gangName, setGangName] = useState("");
  const [username, setUsernameUser] = useState("")

  const [mPower, setMPower] = useState(0);
  const [lCCashe, setLCCashe] = useState(0);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [drugDeals, setDrugDeals] = useState(0);
  const [compromisedDrops, setCompromisedDrops] = useState(0);
  const [interceptedDrops, setInterceptedDrops] = useState(0);
  const [policeShootout, setPoliceShootout] = useState(0);
  const [stopSearch, setStopSearchReal] = useState(0);
  const [succesfullDrops, setSuccessfulDrops] = useState(0);
  const [totalArrests, setTotalArrests] = useState(0);

  const [drugDealsp, setDrugDealsp] = useState(0);
  const [compromisedDropsp, setCompromisedDropsp] = useState(0);
  const [interceptedDropsp, setInterceptedDropsp] = useState(0);
  const [policeShootoutp, setPoliceShootoutp] = useState(0);
  const [stopSearchp, setStopSearchRealp] = useState(0);
  const [succesfullDropsp, setSuccessfulDropsp] = useState(0);
  const [totalArrestsp, setTotalArrestsp] = useState(0);
  const [timeCheckIn, setTimeCheckIn] = useState(0);
  const [dayCheckIn, setDayCheckIn] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const store = useSelector((state) => state);
  const { setError } = useError();
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    if (ual.activeUser) {
      const value = await anchorGetGRank(ual);
      setGRank(parseFloat(value).toFixed(1));
      const MiningP_Rank = await anchorGetMPower(ual, "", 0);
      setMPower(MiningP_Rank);
      const LCleanCashe = await anchorGetMLCleanCashe(ual);
      setLCCashe(LCleanCashe);
      const StopSearchEvent = await anchorGetuserinfors(ual, "stop_search"); //TODO THIS IS RETARDED
      setStopSearch(StopSearchEvent);
      const SucessfulDrop = await anchorGetuserinfors(ual, "successful_drops");
      setSucessfulDrop(SucessfulDrop);
      const shootout = await anchorGetuserinfors(ual, "shoot_out");
      setShootout(shootout);
      const image_id = await anchorGetImageId(ual);
      setImageId(image_id)
      const username = await getUsername(ual)
      setUsernameUser(username, setError)
      const checkIn = await getTimeCheckIn(ual);
      const current_time = Math.floor(Date.now() / 1000)
      setCurrentTime(current_time)
      setTimeCheckIn(checkIn[0].time)
      setDayCheckIn(checkIn[0].day)

      const world_stats = await anchorGetWorldStats(ual);
        setDrugDeals(world_stats.drug_deals)
        setCompromisedDrops(world_stats.compromised_drops)
        setInterceptedDrops(world_stats.intercepted_drops)
        setPoliceShootout(world_stats.police_shootouts)
        setStopSearchReal(world_stats.stop_search)
        setSuccessfulDrops(world_stats.successful_drops)
        setTotalArrests(world_stats.total_arrests)

      const world_stats_p = await anchorGetWorldStatsP(ual);
        setDrugDealsp(world_stats_p.drug_dealsp)
        setCompromisedDropsp(world_stats_p.compromised_dropsp)
        setInterceptedDropsp(world_stats_p.intercepted_dropsp)
        setPoliceShootoutp(world_stats_p.police_shootoutsp)
        setStopSearchRealp(world_stats_p.stop_searchp)
        setSuccessfulDropsp(world_stats_p.successful_dropsp)
        setTotalArrestsp(world_stats_p.total_arrestsp)
    }
  }, [ual, store]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function getLastAction() {
    const value7 = await getLastActionMessage(ual);
    setPopUpMessage(value7)
    setIsPopupOpen(true);
  }

  async function registerName() {
    if (gangName !== "") {
      await setUsername(ual, gangName)
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    refreshAll(dispatch)
  }

  async function checkIn() {

    await checkInBlockchain(ual, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    refreshAll(dispatch)
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const hours = Math.floor(minutes / 60);
  
    if (hours > 0) {
      return `${hours}:${minutes % 60 < 10 ? `0${minutes % 60}` : minutes % 60}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }
    
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };  

  return (
    <div>
      <img src={Safehouse} style={{ position: 'fixed', top: 0, left: 0, width: "100%", height: "100vh", opacity: '0.3', zIndex: "0" }} />

      <div className="mx-2 mr-2 mb-5 rounded-md py-8 px-5 text-lg border border-[#252525] text-white text-center justify-center items-center" style={{ backgroundSize: "cover", position: "relative" }}>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col flex-1 w-1/3">

          </div>
          <div className="flex flex-col flex-1 w-1/3 text-center">
            <div className="w-full flex flex-col items-center justify-center mb-2 relative">
              <TooltipForAvatar />
            </div>
          </div>
          <div className="flex flex-col flex-1 w-1/3">

          </div>
        </div>
        <div className="flex flex-row mb-4">
          <div className="flex flex-col  w-1/3 ">

          </div>

          <div className="flex flex-col w-1/3 p-4 justify-center items-center">

            {!isOpen && gRank > 5 && (
              <div
                className={`relative flex items-center justify-center text-lime-400 bg-[#252525] opacity-75 hover:bg-[#353333]`}
                style={{
                  width: "250px",
                  height: "250px",
                  margin: "1rem",
                  borderRadius:"40px"
                }}
                onClick={() => setIsOpen(true)} // Open ProfileForm on click
              >
                {imageId && (
                  <img
                    src={`https://ipfs.io/ipfs/${imageId}`}
                    alt="Your Image"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                )}
                {!imageId && (
                  <button
                    className="flex items-center justify-center border border-[#2F2F2E] text-lime-400 opacity-75 bg-[#252525] over:bg-[#353333]"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={() => setIsOpen(true)} // Open ProfileForm on click
                  >
                    <FaPlus size={40} />
                  </button>
                )}
              </div>
            )}
            {isOpen && (
              <ProfileForm setIsOpen={setIsOpen} isOpen={isOpen} ual={ual} />
            )}
          </div>

        </div>
        {gRank > 5 && (
          <div className="flex flex-row mb-4">
            <div className="flex flex-col w-1/3 ">

            </div>

            <div className="flex flex-col w-1/3 my-3">
              <MyTextField
                className="text-base text-white text-center"
                placeholder="Enter gang name..."
                focused={false}
                size="small"
                value={gangName}
                onChange={(e) => setGangName(e.target.value)}
              />
            </div>
            <div className="flex flex-col flex-1 pl-5 justify-center" >
              <div className="w-full flex flex-col items-start justify-center mb-2 relative">
                <TooltipForWarning />
              </div>
            </div>
          </div>
        )}

        {gRank > 5 && (
          <div className="flex flex-row  mb-4">
            <div className="flex flex-col w-1/3 ">

            </div>
            <div className='buttonContainer w-1/3 '  >
              <button className="w-full py-1" onClick={() => registerName()}>Register</button>
            </div>
            <div className="flex flex-col flex-1 justify-center pl-5">
              <div className="w-full flex flex-col items-start mb-2 relative">
                <TooltipForRegisterInfo />
              </div>
            </div>
          </div>
        )}

        {gRank >= 25 && (timeCheckIn === 0 || currentTime > timeCheckIn - 10800 ) && (
          <div className="flex flex-row  mb-4">
            <div className="flex flex-col w-1/3 ">

            </div>
            <div className='buttonContainer w-1/3 '  >
              <button className="w-full py-1" onClick={() => checkIn()}>Check In Day: {dayCheckIn}</button>
            </div>
            <div className="flex flex-col flex-1 justify-center pl-5">
              <div className="w-full flex flex-col items-start mb-2 relative">
                <CheckInInfo />
              </div>
            </div>
          </div>
        )}

        {gRank >= 25 && !(timeCheckIn === 0 || currentTime > timeCheckIn - 10800 ) && (
          <div className="flex flex-row  mb-4">
            <div className="flex flex-col w-1/3 ">

            </div>
            <div className='buttonContainer w-1/3 '  >
              <span className="text-white text-xl font-bold">
                Time left to check in: {formatTime(timeCheckIn - currentTime - 10800)}
              </span>
            </div>
            <div className="flex flex-col flex-1 justify-center pl-5">
              <div className="w-full flex flex-col items-start mb-2 relative">
                <CheckInInfoTime />
              </div>
            </div>
          </div>
        )}
        
        <div className="flex flex-row mb-4">
          <div className="flex flex-col w-1/3 ">

          </div>
          <div className='buttonContainer flex flex-col w-1/3'  >
              <button className="w-full py-1 p-2 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E]  text-lime-400 hover:bg-[#252525] rounded-md font-bold flex flex-1" onClick={(e) => { getLastAction() }} style={{color:"red"}}>Get Report</button>
            </div>
        </div>
        

        <div className="flex flex-row mb-4">
        <div className="flex flex-col w-1/3 ">

        </div>
          {isPopupOpen && (

            <div className="flex flex-col items-center w-full bg-[#1a1b1f] border border-solid justify-center border-gray-500 rounded-xl py-4 px-6 w-1/3 my-5"
              style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}>
              <p className="text-white text-md font-semibold mb-4" style={{ textAlign: 'center' }}>
                {popUpMessage}
              </p>
              <button className='buttonContainer ' 
                onClick={() => setIsPopupOpen(false)}>
                Close
              </button>
            </div>
          )}
        </div>
        <div className=" flex w-full flex-col gap-x-24 gap-y-24 md:flex-row md:justify-center">
          <div className="buttonContainer mx-auto flex w-3/4 flex-col gap-y-5 md:mx-0 md:w-2/5">
            <table className="w-full border border-[#2F2F2E] text-center p-1">
              <thead>
                <tr className="p-2">
                  <th className="text-[#787878] text-bold-900 justify-center w-full p-4" colspan={2}>
                    Gang Stats
                  </th>
                </tr>
              </thead>
              <tbody>
                <div className="p-1">
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Name
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      {username}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      G Rank
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      {gRank}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Total Bonus Reward
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      0%
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Dirty Cash per sec
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      {parseFloat(parseFloat(mPower).toFixed(6))}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Dirty Cash per min
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      {parseFloat(parseFloat(mPower * 60).toFixed(6))}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Dirty Cash per hour
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      {parseFloat(parseFloat(mPower * 3600).toFixed(6))}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Laundered Clean Cash
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      {parseFloat(parseFloat(lCCashe).toFixed(6))}
                    </td>
              </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Successful Collections
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                {drugDealsp}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Stop & Searches
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                  {stopSearchp}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Police Shootouts
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                  {policeShootoutp}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Successful Drops
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                  {succesfullDropsp}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Compromised Drops
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                {compromisedDropsp}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Intercepted Drops
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                {interceptedDropsp}
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Times Arrested
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                {totalArrestsp}
                    </td>
                  </tr>
                </div>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex w-full flex-col gap-x-24 gap-y-24 md:flex-row md:justify-center">
          <div className="buttonContainer mx-auto flex w-3/4 flex-col gap-y-5 md:mx-0 md:w-2/5">
            <table className="w-full border border-[#2F2F2E] text-center">
              <thead>
                <tr className="p-2">
                  <th className="text-[#787878] text-bold-900 justify-center w-full p-4" colspan={2}>
                    World Stats
                  </th>
                </tr>
              </thead>
              <tbody className="p-1">
                <div className="p-1">
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Successful Drug Deals
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      <div>
                        {drugDeals}
                      </div>
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Stop & Searches
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      <div>
                        {stopSearch}
                      </div>
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Police Shootouts
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      <div>
                        {policeShootout}
                      </div>
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Successful Drops
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      <div>
                        {succesfullDrops}
                      </div>
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Compromised Drops
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      <div>
                        {compromisedDrops}
                      </div>
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Intercepted Drops
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      <div>
                        {interceptedDrops}
                      </div>
                    </td>
                  </tr>
                  <tr className="p-2 border-t border-[#2F2F2E] flex w-full">
                    <td className="w-1/2 p-2 mr-1">
                      Total Arrests
                    </td>
                    <td className="flex w-1/2 p-2 items-center justify-center">
                      <div>
                        {totalArrests}
                      </div>
                    </td>
                  </tr>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  );
};

class TooltipForAvatar extends Component {
  render() {
    return (
      <Tippy
        content={<span className="">At G Rank 100 and Hustling power 0.0192 you can add PFP to your profile</span>}
        popperOptions={{
          placement: "right",
        }}
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

class TooltipForRegisterInfo extends Component {
  render() {
    return (
      <Tippy
        content={<span className="">Registered Gang will appear on the leaderboard. To register a gang you will need to be G rank level 100 with a 0.0192 Dirty Cash per Sec hustling power. The initial registration will cost 100 Clean Cash, followed by subsequent registrations at a rate of 20 Clean each</span>}
        popperOptions={{
          placement: "right",
        }}
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

class CheckInInfo extends Component {
  render() {
    return (
      <Tippy
        content={<span className="">Check in daily to get a reward(maybe)!</span>}
        popperOptions={{
          placement: "right",
        }}
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

class CheckInInfoTime extends Component {
  render() {
    return (
      <Tippy
        content={<span className="">You have to wait a bit to check in!</span>}
        popperOptions={{
          placement: "right",
        }}
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

class TooltipForLuckyInfo extends Component {
  render() {
    return (
      <Tippy
        content={<span className="">Gamble 0.0085 HTT for a chance to win a Hustle Ingredient! You need to have a minimum of 2 HDC in your in game wallet to use the Trickster!</span>}
        popperOptions={{
          placement: "right",
        }}
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

class TooltipForWarning extends Component {
  render() {
    return (
      <Tippy
        content={
          <span className="tooltip-content">
            Warning: Gang names must be free of offensive or discriminatory words. Ban risk looms if rules aren't respected!
          </span>
        }
        popperOptions={{
          placement: "right",
        }}
        theme="light" // Add theme for better visibility
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

const MyTextField = styled(TextField)({
  border: '1px #787878 solid',
  borderRadius: '5px',
  input: {
    color: '#fff'
  }
})

export default Profile;
