import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hat from "assets/images/gangwars/hat.png"
import Pants from "assets/images/gangwars/pants.png"
import Jacket from "assets/images/gangwars/jacket.png"
import Shoes from "assets/images/gangwars/shoes.png"
import Stopwatch from "assets/images/gangwars/stopwatch.png"
import Gloves from "assets/images/gangwars/gloves.png"
import Gun from "assets/images/gangwars/gun.png"
import Necklace from "assets/images/gangwars/necklace.png"
import Riley from "assets/images/gangwars/riley.png"
import { FaPlus } from "react-icons/fa";
import AddItemPopup from "./AddItemPopup.js"
import RemoveItemPopup from "./RemoveItemPopup.js"
import AddCharPopup from "./AddCharPopup.js"
import RemoveCharPopup from "./RemoveCharPopup.js"

import {refreshAll, anchorGetCharacterPvp, anchorGetDataFromAsset} from "BlockchainInteractionWax";

const MiddleContent = ({ ual }) => {

  const [pvpStats, setPvpStats] = useState([]);
  const [nftUsed, setNftUsed] = useState([])
  const [weaponUsed, setWeaponUsed] = useState([])
  const [accessoryUsed, setAccessorytUsed] = useState([])
  const [glovesUsed, setGlovesUsed] = useState([])
  const [trinketUsed, setTrinketUsed] = useState([])
  const [headUsed, setHeadUsed] = useState([])
  const [jacketUsed, setJacketUsed] = useState([])
  const [pantsUsed, setPantsUsed] = useState([])
  const [shoesUsed, setShoesUsed] = useState([])
  const [isOpenAddWeapon, setIsOpenAddWeapon] = useState(false);
  const [isOpenRemoveWeapon, setIsOpenRemoveWeapon] = useState(false);
  const [isOpenAddChar, setIsOpenAddChar] = useState(false);
  const [isOpenRemoveChar, setIsOpenRemoveChar] = useState(false);
  const [isOpenAddTrinket, setIsOpenAddTrinket] = useState(false);
  const [isOpenRemoveTrinket, setIsOpenRemoveTrinket] = useState(false);
  const [isOpenAddGloves, setIsOpenAddGloves] = useState(false);
  const [isOpenRemoveGloves, setIsOpenRemoveGloves] = useState(false);
  const [isOpenAddAccessory, setIsOpenAddAccessory] = useState(false);
  const [isOpenRemoveAccessory, setIsOpenRemoveAccessory] = useState(false);
  const [isOpenAddHat, setIsOpenAddHat] = useState(false);
  const [isOpenRemoveHat, setIsOpenRemoveHat] = useState(false);
  const [isOpenAddJacket, setIsOpenAddJacket] = useState(false);
  const [isOpenRemoveJacket, setIsOpenRemoveJacket] = useState(false);
  const [isOpenAddPants, setIsOpenAddPants] = useState(false);
  const [isOpenRemovePants, setIsOpenRemovePants] = useState(false);
  const [isOpenAddShoes, setIsOpenAddShoes] = useState(false);
  const [isOpenRemoveShoes, setIsOpenRemoveShoes] = useState(false);
  
  const fetchData = async () => {
    const value = await anchorGetCharacterPvp(ual)
    setPvpStats(value)
    if(value.nft_used !== 0 )
      {
      const value1 = await anchorGetDataFromAsset(value.nft_used)
      setNftUsed(value1)
      }
    if(value.weapon !== 0 )
      {
      const value1 = await anchorGetDataFromAsset(value.weapon)
      setWeaponUsed(value1)
      }
    if(value.accessory !== 0 )
      {
      const value1 = await anchorGetDataFromAsset(value.accessory)
      setAccessorytUsed(value1)
      }
    if(value.gloves !== 0 )
      {
      const value1 = await anchorGetDataFromAsset(value.gloves)
      setGlovesUsed(value1)
      }
    if(value.trinket !== 0 )
      {
      const value1 = await anchorGetDataFromAsset(value.trinket)
      setTrinketUsed(value1)
      }
    if(value.head !== 0 )
      {
      const value1 = await anchorGetDataFromAsset(value.head)
      setHeadUsed(value1)
      }
    if(value.jacket !== 0 )
      {
      const value1 = await anchorGetDataFromAsset(value.jacket)
      setJacketUsed(value1)
      }
    if(value.pants !== 0 )
      {
      const value1 = await anchorGetDataFromAsset(value.pants)
      setPantsUsed(value1)
      }
    if(value.shoes !== 0 )
      {
      const value1 = await anchorGetDataFromAsset(value.shoes)
      setShoesUsed(value1)
      }
  };

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, [store, ual]);

  async function handleUnstake() {
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  const handleWeaponClick = () => {
    setIsOpenAddWeapon(true);
  };

  const handleRemoveWeaponClick = () => {
    setIsOpenRemoveWeapon(true);
  };
  const handleAddCharClick = () => {
    setIsOpenAddChar(true);
  }

  const handleRemoveCharClick = () => {
    setIsOpenRemoveChar(true);
  };

  const handleRemoveTrinket = () => {
    setIsOpenRemoveTrinket(true);
  };

  const handleAddTrinket = () => {
    setIsOpenAddTrinket(true);
  };

  const handleRemoveGloves = () => {
    setIsOpenRemoveGloves(true);
  };

  const handleAddGloves = () => {
    setIsOpenAddGloves(true);
  };

  const handleRemoveAccessory = () => {
    setIsOpenRemoveAccessory(true);
  };

  const handleAddAccessory = () => {
    setIsOpenAddAccessory(true);
  };

  const handleRemoveHat = () => {
    setIsOpenRemoveHat(true);
  };

  const handleAddHat = () => {
    setIsOpenAddHat(true);
  };

  const handleRemoveJacket = () => {
    setIsOpenRemoveJacket(true);
  };

  const handleAddJacket = () => {
    setIsOpenAddJacket(true);
  };

  const handleRemovePants = () => {
    setIsOpenRemovePants(true);
  };

  const handleAddPants = () => {
    setIsOpenAddPants(true);
  };

  const handleRemoveShoes = () => {
    setIsOpenRemoveShoes(true);
  };

  const handleAddShoes = () => {
    setIsOpenAddShoes(true);
  };

  return (
    <div className="w-full h-full lg:w-1/3 pt-8 px-4">
    <div className="buttonContainer custom-shadow mx-auto box-border overflow-hidden rounded-md border border-[#2F2F2E] text-center p-3">
    <div className="grid grid-cols-7 gap-4 ml-7 mr-7">
          
      <div className="flex flex-col items-center justify-between gap-3">
            {weaponUsed.image !== undefined ? (
              <div className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative group"
                onClick={handleRemoveWeaponClick}>
                <img src={`https://ipfs.io/ipfs/${weaponUsed.image}`} className="absolute inset-0 w-full h-full object-fit rounded-md" />
                <RemoveItemPopup isOpen={isOpenRemoveWeapon} setIsOpen={setIsOpenRemoveWeapon} ual={ual} type="Weapon" asset_id={weaponUsed.asset_id} />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md">
                  {/* Name and Stats Section */}
                  <div className="flex flex-col text-center text-white space-y-1 w-full px-3">
                    {/* Item Name */}
                    <span className="font-bold text-xs">{weaponUsed.name}</span>

                    {/* Stats (aligned in a grid) */}
                    <div className="grid grid-cols-2 gap-x-4">
                      {weaponUsed.power !== 0 && <span className="text-xs">Power:</span>}
                      {weaponUsed.power !== 0 && <span className="text-xs">{parseFloat(weaponUsed.power).toFixed(2)}</span>}
                      
                      {weaponUsed.defense !== 0 && <span className="text-xs">Defense:</span>}
                      {weaponUsed.defense !== 0 && <span className="text-xs">{parseFloat(weaponUsed.defense).toFixed(2)}</span>}
                      
                      {weaponUsed.hp !== 0 && <span className="text-xs">HP:</span>}
                      {weaponUsed.hp !== 0 && <span className="text-xs">{parseFloat(weaponUsed.hp).toFixed(2)}</span>}
                      
                      {weaponUsed.accuracy !== 0 && <span className="text-xs">Accuracy:</span>}
                      {weaponUsed.accuracy !== 0 && <span className="text-xs">{parseFloat(weaponUsed.accuracy).toFixed(2)}</span>}
                      
                      {weaponUsed.evasion !== 0 && <span className="text-xs">Evasion:</span>}
                      {weaponUsed.evasion !== 0 && <span className="text-xs">{parseFloat(weaponUsed.evasion).toFixed(2)}</span>}
                      
                      {weaponUsed.dodge !== 0 && <span className="text-xs">Dodge:</span>}
                      {weaponUsed.dodge !== 0 && <span className="text-xs">{parseFloat(weaponUsed.dodge).toFixed(2)}</span>}
                      
                      {weaponUsed.crit !== 0 && <span className="text-xs">Crit:</span>}
                      {weaponUsed.crit !== 0 && <span className="text-xs">{parseFloat(weaponUsed.crit).toFixed(2)}</span>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
                onClick={handleWeaponClick}                
              >
                <img src={Gun} className="absolute inset-0 w-full h-full object-fit opacity-30 rounded-md" />
                <FaPlus className="absolute inset-0 m-auto text-5xl text-gray-500" />
                <AddItemPopup isOpen={isOpenAddWeapon} setIsOpen={setIsOpenAddWeapon} ual={ual} type="Weapon" />
              </div>
            )}         
            {trinketUsed.image !== undefined ? (
              <div className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative group"
                onClick={handleRemoveTrinket}>
                <img src={`https://ipfs.io/ipfs/${trinketUsed.image}`} className="absolute inset-0 w-full h-full object-fit rounded-md" />
                <RemoveItemPopup isOpen={isOpenRemoveTrinket} setIsOpen={setIsOpenRemoveTrinket} ual={ual} type="Trinket" asset_id={trinketUsed.asset_id} />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md">
                  {/* Name and Stats Section */}
                  <div className="flex flex-col text-center text-white space-y-1 w-full px-3">
                    {/* Item Name */}
                    <span className="font-bold text-xs">{trinketUsed.name}</span>

                    {/* Stats (aligned in a grid) */}
                    <div className="grid grid-cols-2 gap-x-4">
                      {trinketUsed.power !== 0 && <span className="text-xs">Power:</span>}
                      {trinketUsed.power !== 0 && <span className="text-xs">{parseFloat(trinketUsed.power).toFixed(2)}</span>}
                      
                      {trinketUsed.defense !== 0 && <span className="text-xs">Defense:</span>}
                      {trinketUsed.defense !== 0 && <span className="text-xs">{parseFloat(trinketUsed.defense).toFixed(2)}</span>}
                      
                      {trinketUsed.hp !== 0 && <span className="text-xs">HP:</span>}
                      {trinketUsed.hp !== 0 && <span className="text-xs">{parseFloat(trinketUsed.hp).toFixed(2)}</span>}
                      
                      {trinketUsed.accuracy !== 0 && <span className="text-xs">Accuracy:</span>}
                      {trinketUsed.accuracy !== 0 && <span className="text-xs">{parseFloat(trinketUsed.accuracy).toFixed(2)}</span>}
                      
                      {trinketUsed.evasion !== 0 && <span className="text-xs">Evasion:</span>}
                      {trinketUsed.evasion !== 0 && <span className="text-xs">{parseFloat(trinketUsed.evasion).toFixed(2)}</span>}
                      
                      {trinketUsed.dodge !== 0 && <span className="text-xs">Dodge:</span>}
                      {trinketUsed.dodge !== 0 && <span className="text-xs">{parseFloat(trinketUsed.dodge).toFixed(2)}</span>}
                      
                      {trinketUsed.crit !== 0 && <span className="text-xs">Crit:</span>}
                      {trinketUsed.crit !== 0 && <span className="text-xs">{parseFloat(trinketUsed.crit).toFixed(2)}</span>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
                onClick={handleAddTrinket}                
              >
                <img src={Necklace} className="absolute inset-0 w-full h-full object-fit opacity-30 rounded-md" />
                <FaPlus className="absolute inset-0 m-auto text-5xl text-gray-500" />
                <AddItemPopup isOpen={isOpenAddTrinket} setIsOpen={setIsOpenAddTrinket} ual={ual} type="Trinket" />
              </div>
            )}    
            {glovesUsed.image !== undefined ? (
              <div className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative group"
                onClick={handleRemoveGloves}>
                <img src={`https://ipfs.io/ipfs/${glovesUsed.image}`} className="absolute inset-0 w-full h-full object-fit rounded-md" />
                <RemoveItemPopup isOpen={isOpenRemoveGloves} setIsOpen={setIsOpenRemoveGloves} ual={ual} type="Arms" asset_id={glovesUsed.asset_id} />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md">
                  {/* Name and Stats Section */}
                  <div className="flex flex-col text-center text-white space-y-1 w-full px-3">
                    {/* Item Name */}
                    <span className="font-bold text-xs">{glovesUsed.name}</span>

                    {/* Stats (aligned in a grid) */}
                    <div className="grid grid-cols-2 gap-x-4">
                      {glovesUsed.power !== 0 && <span className="text-xs">Power:</span>}
                      {glovesUsed.power !== 0 && <span className="text-xs">{parseFloat(glovesUsed.power).toFixed(2)}</span>}
                      
                      {glovesUsed.defense !== 0 && <span className="text-xs">Defense:</span>}
                      {glovesUsed.defense !== 0 && <span className="text-xs">{parseFloat(glovesUsed.defense).toFixed(2)}</span>}
                      
                      {glovesUsed.hp !== 0 && <span className="text-xs">HP:</span>}
                      {glovesUsed.hp !== 0 && <span className="text-xs">{parseFloat(glovesUsed.hp).toFixed(2)}</span>}
                      
                      {glovesUsed.accuracy !== 0 && <span className="text-xs">Accuracy:</span>}
                      {glovesUsed.accuracy !== 0 && <span className="text-xs">{parseFloat(glovesUsed.accuracy).toFixed(2)}</span>}
                      
                      {glovesUsed.evasion !== 0 && <span className="text-xs">Evasion:</span>}
                      {glovesUsed.evasion !== 0 && <span className="text-xs">{parseFloat(glovesUsed.evasion).toFixed(2)}</span>}
                      
                      {glovesUsed.dodge !== 0 && <span className="text-xs">Dodge:</span>}
                      {glovesUsed.dodge !== 0 && <span className="text-xs">{parseFloat(glovesUsed.dodge).toFixed(2)}</span>}
                      
                      {glovesUsed.crit !== 0 && <span className="text-xs">Crit:</span>}
                      {glovesUsed.crit !== 0 && <span className="text-xs">{parseFloat(glovesUsed.crit).toFixed(2)}</span>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
                onClick={handleAddGloves}                
              >
                <img src={Gloves} className="absolute inset-0 w-full h-full object-fit opacity-30 rounded-md" />
                <FaPlus className="absolute inset-0 m-auto text-5xl text-gray-500" />
                <AddItemPopup isOpen={isOpenAddGloves} setIsOpen={setIsOpenAddGloves} ual={ual} type="Arms" />
              </div>
            )}     
            {accessoryUsed.image !== undefined ? (
              <div className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative group"
                onClick={handleRemoveAccessory}>
                <img src={`https://ipfs.io/ipfs/${accessoryUsed.image}`} className="absolute inset-0 w-full h-full object-fit rounded-md" />
                <RemoveItemPopup isOpen={isOpenRemoveAccessory} setIsOpen={setIsOpenRemoveAccessory} ual={ual} type="Accessory" asset_id={accessoryUsed.asset_id} />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md">
                  {/* Name and Stats Section */}
                  <div className="flex flex-col text-center text-white space-y-1 w-full px-3">
                    {/* Item Name */}
                    <span className="font-bold text-xs">{accessoryUsed.name}</span>

                    {/* Stats (aligned in a grid) */}
                    <div className="grid grid-cols-2 gap-x-4">
                      {accessoryUsed.power !== 0 && <span className="text-xs">Power:</span>}
                      {accessoryUsed.power !== 0 && <span className="text-xs">{parseFloat(accessoryUsed.power).toFixed(2)}</span>}
                      
                      {accessoryUsed.defense !== 0 && <span className="text-xs">Defense:</span>}
                      {accessoryUsed.defense !== 0 && <span className="text-xs">{parseFloat(accessoryUsed.defense).toFixed(2)}</span>}
                      
                      {accessoryUsed.hp !== 0 && <span className="text-xs">HP:</span>}
                      {accessoryUsed.hp !== 0 && <span className="text-xs">{parseFloat(accessoryUsed.hp).toFixed(2)}</span>}
                      
                      {accessoryUsed.accuracy !== 0 && <span className="text-xs">Accuracy:</span>}
                      {accessoryUsed.accuracy !== 0 && <span className="text-xs">{parseFloat(accessoryUsed.accuracy).toFixed(2)}</span>}
                      
                      {accessoryUsed.evasion !== 0 && <span className="text-xs">Evasion:</span>}
                      {accessoryUsed.evasion !== 0 && <span className="text-xs">{parseFloat(accessoryUsed.evasion).toFixed(2)}</span>}
                      
                      {accessoryUsed.dodge !== 0 && <span className="text-xs">Dodge:</span>}
                      {accessoryUsed.dodge !== 0 && <span className="text-xs">{parseFloat(accessoryUsed.dodge).toFixed(2)}</span>}
                      
                      {accessoryUsed.crit !== 0 && <span className="text-xs">Crit:</span>}
                      {accessoryUsed.crit !== 0 && <span className="text-xs">{parseFloat(accessoryUsed.crit).toFixed(2)}</span>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
                onClick={handleAddAccessory}                
              >
                <img src={Stopwatch} className="absolute inset-0 w-full h-full object-fit opacity-30 rounded-md" />
                <FaPlus className="absolute inset-0 m-auto text-5xl text-gray-500" />
                <AddItemPopup isOpen={isOpenAddAccessory} setIsOpen={setIsOpenAddAccessory} ual={ual} type="Accessory" />
              </div>
            )}            
          </div>
  
          <div className="col-span-5 flex items-center justify-center">
          {nftUsed.image !== undefined ? (
            <div 
              className="w-auto min-w-[7rem] sm:min-w-[8rem] md:min-w-[10rem] lg:min-w-[12rem] 
                        h-auto min-h-[14rem] sm:min-h-[14rem] md:min-h-[18rem] lg:min-h-[20rem] 
                        bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
              onClick={handleRemoveCharClick}
            >
              <img src={`https://ipfs.io/ipfs/${nftUsed.image}`} className="absolute inset-0 w-full h-full object-fit rounded-md" />
              <RemoveCharPopup isOpen={isOpenRemoveChar} setIsOpen={setIsOpenRemoveChar} ual={ual} asset_id={nftUsed.asset_id} />
            </div>
          ) : (
            <div 
              className="w-auto min-w-[7rem] sm:min-w-[9rem] md:min-w-[12rem] lg:min-w-[15rem] 
                        h-auto min-h-[14rem] sm:min-h-[16rem] md:min-h-[20rem] lg:min-h-[24rem] 
                        bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
              onClick={handleAddCharClick}
            >
              <img src={Riley} className="absolute inset-0 w-full h-full object-fit opacity-30 rounded-md" />
              <FaPlus className="absolute inset-0 m-auto text-4xl text-gray-500" />
              <AddCharPopup isOpen={isOpenAddChar} setIsOpen={setIsOpenAddChar} ual={ual} />
            </div>
          )}
        </div>

  
          {/* Right Column with 4 Rows */}
          <div className="flex flex-col items-center justify-between gap-3">
          {headUsed.image !== undefined ? (
              <div className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative group"
                onClick={handleRemoveHat}>
                <img src={`https://ipfs.io/ipfs/${headUsed.image}`} className="absolute inset-0 w-full h-full object-fit rounded-md" />
                <RemoveItemPopup isOpen={isOpenRemoveHat} setIsOpen={setIsOpenRemoveHat} ual={ual} type="Head" asset_id={headUsed.asset_id} />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md">
                  {/* Name and Stats Section */}
                  <div className="flex flex-col text-center text-white space-y-1 w-full px-3">
                    {/* Item Name */}
                    <span className="font-bold text-xs">{headUsed.name}</span>

                    {/* Stats (aligned in a grid) */}
                    <div className="grid grid-cols-2 gap-x-4">
                      {headUsed.power !== 0 && <span className="text-xs">Power:</span>}
                      {headUsed.power !== 0 && <span className="text-xs">{parseFloat(headUsed.power).toFixed(2)}</span>}
                      
                      {headUsed.defense !== 0 && <span className="text-xs">Defense:</span>}
                      {headUsed.defense !== 0 && <span className="text-xs">{parseFloat(headUsed.defense).toFixed(2)}</span>}
                      
                      {headUsed.hp !== 0 && <span className="text-xs">HP:</span>}
                      {headUsed.hp !== 0 && <span className="text-xs">{parseFloat(headUsed.hp).toFixed(2)}</span>}
                      
                      {headUsed.accuracy !== 0 && <span className="text-xs">Accuracy:</span>}
                      {headUsed.accuracy !== 0 && <span className="text-xs">{parseFloat(headUsed.accuracy).toFixed(2)}</span>}
                      
                      {headUsed.evasion !== 0 && <span className="text-xs">Evasion:</span>}
                      {headUsed.evasion !== 0 && <span className="text-xs">{parseFloat(headUsed.evasion).toFixed(2)}</span>}
                      
                      {headUsed.dodge !== 0 && <span className="text-xs">Dodge:</span>}
                      {headUsed.dodge !== 0 && <span className="text-xs">{parseFloat(headUsed.dodge).toFixed(2)}</span>}
                      
                      {headUsed.crit !== 0 && <span className="text-xs">Crit:</span>}
                      {headUsed.crit !== 0 && <span className="text-xs">{parseFloat(headUsed.crit).toFixed(2)}</span>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
                onClick={handleAddHat}                
              >
                <img src={Hat} className="absolute inset-0 w-full h-full object-fit opacity-30 rounded-md" />
                <FaPlus className="absolute inset-0 m-auto text-5xl text-gray-500" />
                <AddItemPopup isOpen={isOpenAddHat} setIsOpen={setIsOpenAddHat} ual={ual} type="Head" />
              </div>
            )}            
            {jacketUsed.image !== undefined ? (
              <div className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative group"
                onClick={handleRemoveJacket}>
                <img src={`https://ipfs.io/ipfs/${jacketUsed.image}`} className="absolute inset-0 w-full h-full object-fit rounded-md" />
                <RemoveItemPopup isOpen={isOpenRemoveJacket} setIsOpen={setIsOpenRemoveJacket} ual={ual} type="Chest" asset_id={jacketUsed.asset_id} />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md">
                  {/* Name and Stats Section */}
                  <div className="flex flex-col text-center text-white space-y-1 w-full px-3">
                    {/* Item Name */}
                    <span className="font-bold text-xs">{jacketUsed.name}</span>

                    {/* Stats (aligned in a grid) */}
                    <div className="grid grid-cols-2 gap-x-4">
                      {jacketUsed.power !== 0 && <span className="text-xs">Power:</span>}
                      {jacketUsed.power !== 0 && <span className="text-xs">{parseFloat(jacketUsed.power).toFixed(2)}</span>}
                      
                      {jacketUsed.defense !== 0 && <span className="text-xs">Defense:</span>}
                      {jacketUsed.defense !== 0 && <span className="text-xs">{parseFloat(jacketUsed.defense).toFixed(2)}</span>}
                      
                      {jacketUsed.hp !== 0 && <span className="text-xs">HP:</span>}
                      {jacketUsed.hp !== 0 && <span className="text-xs">{parseFloat(jacketUsed.hp).toFixed(2)}</span>}
                      
                      {jacketUsed.accuracy !== 0 && <span className="text-xs">Accuracy:</span>}
                      {jacketUsed.accuracy !== 0 && <span className="text-xs">{parseFloat(jacketUsed.accuracy).toFixed(2)}</span>}
                      
                      {jacketUsed.evasion !== 0 && <span className="text-xs">Evasion:</span>}
                      {jacketUsed.evasion !== 0 && <span className="text-xs">{parseFloat(jacketUsed.evasion).toFixed(2)}</span>}
                      
                      {jacketUsed.dodge !== 0 && <span className="text-xs">Dodge:</span>}
                      {jacketUsed.dodge !== 0 && <span className="text-xs">{parseFloat(jacketUsed.dodge).toFixed(2)}</span>}
                      
                      {jacketUsed.crit !== 0 && <span className="text-xs">Crit:</span>}
                      {jacketUsed.crit !== 0 && <span className="text-xs">{parseFloat(jacketUsed.crit).toFixed(2)}</span>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
                onClick={handleAddJacket}                
              >
                <img src={Jacket} className="absolute inset-0 w-full h-full object-fit opacity-30 rounded-md" />
                <FaPlus className="absolute inset-0 m-auto text-5xl text-gray-500" />
                <AddItemPopup isOpen={isOpenAddJacket} setIsOpen={setIsOpenAddJacket} ual={ual} type="Chest" />
              </div>
            )}     
            {pantsUsed.image !== undefined ? (
              <div className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative group"
                onClick={handleRemovePants}>
                <img src={`https://ipfs.io/ipfs/${pantsUsed.image}`} className="absolute inset-0 w-full h-full object-fit rounded-md" />
                <RemoveItemPopup isOpen={isOpenRemovePants} setIsOpen={setIsOpenRemovePants} ual={ual} type="Legs" asset_id={pantsUsed.asset_id} />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md">
                  {/* Name and Stats Section */}
                  <div className="flex flex-col text-center text-white space-y-1 w-full px-3">
                    {/* Item Name */}
                    <span className="font-bold text-xs">{pantsUsed.name}</span>

                    {/* Stats (aligned in a grid) */}
                    <div className="grid grid-cols-2 gap-x-4">
                      {pantsUsed.power !== 0 && <span className="text-xs">Power:</span>}
                      {pantsUsed.power !== 0 && <span className="text-xs">{parseFloat(pantsUsed.power).toFixed(2)}</span>}
                      
                      {pantsUsed.defense !== 0 && <span className="text-xs">Defense:</span>}
                      {pantsUsed.defense !== 0 && <span className="text-xs">{parseFloat(pantsUsed.defense).toFixed(2)}</span>}
                      
                      {pantsUsed.hp !== 0 && <span className="text-xs">HP:</span>}
                      {pantsUsed.hp !== 0 && <span className="text-xs">{parseFloat(pantsUsed.hp).toFixed(2)}</span>}
                      
                      {pantsUsed.accuracy !== 0 && <span className="text-xs">Accuracy:</span>}
                      {pantsUsed.accuracy !== 0 && <span className="text-xs">{parseFloat(pantsUsed.accuracy).toFixed(2)}</span>}
                      
                      {pantsUsed.evasion !== 0 && <span className="text-xs">Evasion:</span>}
                      {pantsUsed.evasion !== 0 && <span className="text-xs">{parseFloat(pantsUsed.evasion).toFixed(2)}</span>}
                      
                      {pantsUsed.dodge !== 0 && <span className="text-xs">Dodge:</span>}
                      {pantsUsed.dodge !== 0 && <span className="text-xs">{parseFloat(pantsUsed.dodge).toFixed(2)}</span>}
                      
                      {pantsUsed.crit !== 0 && <span className="text-xs">Crit:</span>}
                      {pantsUsed.crit !== 0 && <span className="text-xs">{parseFloat(pantsUsed.crit).toFixed(2)}</span>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
                onClick={handleAddPants}                
              >
                <img src={Pants} className="absolute inset-0 w-full h-full object-fit opacity-30 rounded-md" />
                <FaPlus className="absolute inset-0 m-auto text-5xl text-gray-500" />
                <AddItemPopup isOpen={isOpenAddPants} setIsOpen={setIsOpenAddPants} ual={ual} type="Legs" />
              </div>
            )}     
            {shoesUsed.image !== undefined ? (
              <div className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative group"
                onClick={handleRemoveShoes}>
                <img src={`https://ipfs.io/ipfs/${shoesUsed.image}`} className="absolute inset-0 w-full h-full object-fit rounded-md" />
                <RemoveItemPopup isOpen={isOpenRemoveShoes} setIsOpen={setIsOpenRemoveShoes} ual={ual} type="Feet" asset_id={shoesUsed.asset_id} />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md">
                  {/* Name and Stats Section */}
                  <div className="flex flex-col text-center text-white space-y-1 w-full px-3">
                    {/* Item Name */}
                    <span className="font-bold text-xs">{shoesUsed.name}</span>

                    {/* Stats (aligned in a grid) */}
                    <div className="grid grid-cols-2 gap-x-4">
                      {shoesUsed.power !== 0 && <span className="text-xs">Power:</span>}
                      {shoesUsed.power !== 0 && <span className="text-xs">{parseFloat(shoesUsed.power).toFixed(2)}</span>}
                      
                      {shoesUsed.defense !== 0 && <span className="text-xs">Defense:</span>}
                      {shoesUsed.defense !== 0 && <span className="text-xs">{parseFloat(shoesUsed.defense).toFixed(2)}</span>}
                      
                      {shoesUsed.hp !== 0 && <span className="text-xs">HP:</span>}
                      {shoesUsed.hp !== 0 && <span className="text-xs">{parseFloat(shoesUsed.hp).toFixed(2)}</span>}
                      
                      {shoesUsed.accuracy !== 0 && <span className="text-xs">Accuracy:</span>}
                      {shoesUsed.accuracy !== 0 && <span className="text-xs">{parseFloat(shoesUsed.accuracy).toFixed(2)}</span>}
                      
                      {shoesUsed.evasion !== 0 && <span className="text-xs">Evasion:</span>}
                      {shoesUsed.evasion !== 0 && <span className="text-xs">{parseFloat(shoesUsed.evasion).toFixed(2)}</span>}
                      
                      {shoesUsed.dodge !== 0 && <span className="text-xs">Dodge:</span>}
                      {shoesUsed.dodge !== 0 && <span className="text-xs">{parseFloat(shoesUsed.dodge).toFixed(2)}</span>}
                      
                      {shoesUsed.crit !== 0 && <span className="text-xs">Crit:</span>}
                      {shoesUsed.crit !== 0 && <span className="text-xs">{parseFloat(shoesUsed.crit).toFixed(2)}</span>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-auto min-w-[7rem] h-auto min-h-[8rem] bg-[#2F2F2E] bg-opacity-70 rounded-md relative"
                onClick={handleAddShoes}                
              >
                <img src={Shoes} className="absolute inset-0 w-full h-full object-fit opacity-30 rounded-md" />
                <FaPlus className="absolute inset-0 m-auto text-5xl text-gray-500" />
                <AddItemPopup isOpen={isOpenAddShoes} setIsOpen={setIsOpenAddShoes} ual={ual} type="Feet" />
              </div>
            )}     
          </div>
  
        </div>
      </div>
    </div>
  );
};



export default MiddleContent;
