import React, { useState, useEffect } from "react";
import DropDown from "components/shared/DropDown/DropDown";
import settingsIcon from "assets/images/icons/settings.png";
import Endpoints from "./MenuItems/Endpoints";

const GearMenus = () => {
  const [isShow, setIsShow] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState(() => {
    // Load the selected endpoint from local storage, or default to null
    const savedEndpoint = localStorage.getItem('selectedEndpoint');
    return savedEndpoint ? JSON.parse(savedEndpoint) : null;
  });

  const MENUS = [
    {
      value: "1",
      label: "Alcor",
      protocol: "https",
      host: "wax-api.alcor.exchange",
      port: "443",
    },
    {
      value: "2",
      label: "3dkrender",
      protocol: "https",
      host: "query.3dkrender.com",
      port: "443",
    },
    {
      value: "3",
      label: "alohaeos",
      protocol: "https",
      host: "api.wax.alohaeos.com",
      port: "443",
    },
    {
      value: "4",
      label: "eos amsterdam",
      protocol: "https",
      host: "wax.eu.eosamsterdam.net",
      port: "443",
    },
    {
      value: "5",
      label: "DetroitLedger",
      protocol: "https",
      host: "api.wax.detroitledger.tech",
      port: "443",
    },
    {
      value: "6",
      label: "Bounty Blok",
      protocol: "https",
      host: "api.wax.bountyblok.io",
      port: "443",
    },
    {
      value: "7",
      label: "Blacklusion",
      protocol: "https",
      host: "wax.blacklusion.io",
      port: "443",
    },    
    {
      value: "8",
      label: "WaxDao",
      protocol: "https",
      host: "api.waxdaobp.io",
      port: "443",
    },
    {
      value: "9",
      label: "crypto lions",
      protocol: "https",
      host: "wax.cryptolions.io",
      port: "443",
    },
    {
      value: "10",
      label: "eos dublin",
      protocol: "https",
      host: "wax.eosdublin.io",
      port: "443",
    },
    {
      value: "11",
      label: "eos rio",
      protocol: "https",
      host: "wax.eosrio.io",
      port: "443",
    },
    {
      value: "12",
      label: "EOSArabia",
      protocol: "https",
      host: "api-wax.eosarabia.net",
      port: "443",
    },
    {
      value: "13",
      label: "DefiBox",
      protocol: "https",
      host: "wax.defibox.xyz",
      port: "443",
    },
    {
      value: "14",
      label: "WAXUSA",
      protocol: "https",
      host: "wax.eosusa.io",
      port: "443",
    },
    {
      value: "15",
      label: "eos usa",
      protocol: "https",
      host: "wax.eosusa.io",
      port: "443",
    },
  ];

  useEffect(() => {
    // Whenever selectedEndpoint changes, update local storage
    if (selectedEndpoint) {
      localStorage.setItem('selectedEndpoint', JSON.stringify(selectedEndpoint));
    }
  }, [selectedEndpoint]);

  const handleEndpointSubmit = (selectedValue) => {
    // Find the endpoint from MENUS based on the selected value
    const endpoint = MENUS.find((menu) => menu.value === selectedValue);
    if (endpoint) {
      setSelectedEndpoint(endpoint);
      console.log("Selected endpoint:", endpoint);
      // Here, you can also reinitialize or update connections based on the new endpoint
    }
  };

  return (
    <DropDown
      isShow={isShow}
      left={-100}
      onClose={setIsShow}
      element={<Endpoints
        list={MENUS.sort((a, b) => {
          if (selectedEndpoint && a.value === selectedEndpoint.value) return -1;
          if (selectedEndpoint && b.value === selectedEndpoint.value) return 1;
          return 0;
        })}
        onSubmit={handleEndpointSubmit}
      />}
    >
      <div>
        <button
          className="w-8 cursor-pointer"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setIsShow(!isShow);
          }}
        >
          <img src={settingsIcon} className="h-full w-full" alt="settings" />
        </button>
      </div>
    </DropDown>
  );
};

export default GearMenus;