import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import icon1 from "assets/images/icons/solo-g.png";
import { useError } from 'ErrorProvider';

import { anchorAddRmItem, refreshAll, anchorGetNFTbyType } from "BlockchainInteractionWax";

const RemoveItemPopup = ({ isOpen, setIsOpen, ual, type, asset_id }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  useEffect(() => {
    const fetchData = async () => {

    };
    fetchData();
  }, [store, ual]);

  async function handleRemoveItem() {
    await anchorAddRmItem(ual,asset_id, 0, type, setError)
    setIsOpen(false);
    await new Promise(resolve => setTimeout(resolve, 10000));
    await refreshAll(dispatch);    
    };

  return (
    <>
<Modal
  isOpen={isOpen}
  onRequestClose={(event) => {
    event.stopPropagation();
    setIsOpen(false);
  }}
>
  <div
    className="w-full bg-[#1a1b1f] border rounded-xl border-[#252525] py-4 px-6"
    style={{
      backgroundImage: "radial-gradient(#292929, #000000)",
      border: "6px outset rgba(165, 165, 165, 0.4)",
    }}
  >
    {/* Centering the text */}
    <div className="mb-2 flex items-center justify-center relative">
      <h2 className="text-center font-Montserrat text-2xl font-bold text-olive">
        Remove {type}
      </h2>
      {/* Positioning the close icon in the top-right corner */}
      <FaTimes
        className="absolute right-0 text-2xl text-olive hover:cursor-pointer"
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
      />
    </div>

    <div className="flex justify-center gap-4 mt-4">
      <div className="buttonContainer">
        <button className="w-32"
         onClick={(event) => {
            event.stopPropagation();
            setIsOpen(false);
          }}>
            No
        </button>
      </div>
      <div className="buttonContainer">
        <button className="w-32"
         onClick={(event) => {
            event.stopPropagation();
            handleRemoveItem();
          }}>
            Yes
        </button>
      </div>
    </div>
  </div>
</Modal>
    </>
  );
};

export default RemoveItemPopup;
