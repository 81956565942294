import React, { createContext, useContext, useState } from 'react';
import ErrorPopup from './ErrorPopup'; // Ensure ErrorPopup is imported

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const handleClose = () => setError(null); // Function to close the popup

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
      {error && <ErrorPopup errorMessage={error} onClose={handleClose} />}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
};