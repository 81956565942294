import React from 'react';
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { useSound } from '../../SoundContext';

const MuteButton = () => {
  const {isMuted, toggleMute} = useSound();

  return (
    <button
      onClick={toggleMute}
      className="p-2 rounded-md bg-transparent border-2 border-[#C0A772] hover:bg-[#2F2F2E] transition-colors"
      style={{
        color: '#C0A772', // Golden color
      }}
    >
      {isMuted ? (
        <FaVolumeMute className="text-lg" />
      ) : (
        <FaVolumeUp className="text-lg" />
      )}
    </button>
  );
};

export default MuteButton;
