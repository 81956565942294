// ErrorPopup.js
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";
import React, { useEffect } from "react";
import soundFile from 'assets/sound/error.mp3'
import { useSound } from './SoundContext'

const ErrorPopup = ({ errorMessage, onClose }) => {

  const { isMuted } = useSound();

  useEffect(() => {
    const audio = new Audio(soundFile);

    if (!isMuted) {
      audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
      });
    }
  }, [isMuted]);

  return (
    <Modal isOpen={!!errorMessage} onRequestClose={onClose}>
      <div
        className="w-full rounded-xl py-4 px-6 font-Montserrat flex flex-col items-center"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <div className="flex items-center justify-between w-full mb-4">
          <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-red-500 mx-auto">
            Error
          </h2>
          <FaTimes className="text-2xl text-white cursor-pointer" onClick={onClose} />
        </div>
        <div className="text-center text-white font-semibold text-lg mb-6">
          <p>{errorMessage}</p>
        </div>
        <button
          className="bg-red-500 text-white rounded-md px-6 py-2 w-40"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ErrorPopup;
