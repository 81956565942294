import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import icon1 from "assets/images/icons/solo-g.png";
import { useError } from 'ErrorProvider';

import { anchorGetNFTsWithoutStats, anchorAddStatsToItem, refreshAll } from "BlockchainInteractionWax";

const AddItemStats = ({ isOpen, setIsOpen, ual }) => {
  const [listData, setListData] = useState([]);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  useEffect(() => {
    const fetchData = async () => {
      if (ual.activeUser !== undefined) {
        const nftData = await anchorGetNFTsWithoutStats(ual)     
        setListData(nftData);
      }
    };
    fetchData();
  }, [store, ual]);

  async function handleAddStats(asset_id) {
    await anchorAddStatsToItem(asset_id, ual, setError)
    setIsOpen(false);
  };

  const levelStyle = {
    position: "absolute",
    top: "75%", // Center the timer vertically
    left: "20%", // Center the timer horizontally
    transform: "translate(-50%, -50%)",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "14px",
    whiteSpace: "nowrap", // Prevent text wrapping
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <div className="w-full bg-[#1a1b1f] border rounded-xl border-[#252525] py-4 px-6" style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-olive">Add Item Stats</h2>
            <FaTimes className="block text-2xl text-olive hover:cursor-pointer" onClick={() => setIsOpen(false)} />
          </div>
          <div>
            {listData.length === 0 && (
              <div className="h-60 flex flex-col w-full h-full items-center justify-center">
                <div className="mt-3 flex flex-col items-center justify-center gap-x-2">
                  <div className="w-20 gap-y-2 mb-4">
                    <img src={icon1} alt="Solo-G" className="object- h-full" />
                  </div>
                  <h1 className="px-5 py-2 text-center font-Montserrat text-md font-bold text-olive">
                    No items staked. <br />
                    Acquire an eligible NFT.
                  </h1>
                </div>
              </div>
            )}
            {listData.length && (
              <ul className="h-80 overflow-y-auto w-full">
                {listData.map((item) => (
                  <li key={item.asset_id} className="flex items-center justify-between p-2 border-b-2 border-gray-500">
                    <div className="relative flex items-center" style={{ width: '33.33%' }}>
                        <img src={`https://ipfs.io/ipfs/${item.image}`} alt="Decoded image" className="mr-2" style={{ width: "100%", height: "auto" }} />
                    </div>
                    <span style={{ color: "white", fontSize: "25px" }}>{item.name}</span>
                    <div className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] flex h-24 w-full items-center justify-center rounded-xl" onClick={() => handleAddStats(item.asset_id)} style={{ width: '33.33%' }}>
                      <FaPlus className="text-5xl text-gray-500" />
                    </div>                 
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddItemStats;
