import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Provider } from 'react-redux';
import { store, persistor } from './store'; 
import { Anchor } from "../node_modules/ual-anchor/dist";
import { ErrorProvider } from 'ErrorProvider';
import { SoundProvider } from './SoundContext'

import { Wax } from "@eosdacio/ual-wax";

import { Wombat } from 'wombat-ual'

import { UALProvider, withUAL } from 'ual-reactjs-renderer'

// Attempt to load the selected endpoint from local storage
const savedEndpoint = localStorage.getItem('selectedEndpoint');
const defaultEndpoint = {
  protocol: "https",
  host: "api.waxsweden.org",
  port: "443",
};
const selectedEndpoint = savedEndpoint ? JSON.parse(savedEndpoint) : defaultEndpoint;

const myChain = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [selectedEndpoint],
};

// Initialize blockchain connectors as before
const anchor = new Anchor([myChain], { appName: "TheHustle" });
const wax = new Wax([myChain], { appName: "TheHustle" });
const wombat = new Wombat([myChain], { appName: "TheHustle" });

const MyUALConsumer = withUAL(App);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render( 
  <SoundProvider>
  <ErrorProvider>
    <Provider store={store} persistor={persistor}>
    <UALProvider
      chains={[myChain]}
      authenticators={[wax, anchor, wombat]}
      appName={"TheHustle"}
    >
      <MyUALConsumer />
    </UALProvider>
    </Provider>
    </ErrorProvider> 
    </SoundProvider>
);