import React, { useEffect, useState, useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useError } from 'ErrorProvider';
import { FaQuestionCircle } from 'react-icons/fa';
import {refreshAll, anchorGetCharacterPvp, anchorSetTactic} from "BlockchainInteractionWax";

const RightContent = ({ ual }) => {

  const [pvpStats, setPvpStats] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0)

  const fetchData = async () => {
    const value = await anchorGetCharacterPvp(ual)
    setPvpStats(value)
    setSelectedItem(value.mode)
  };

  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  useEffect(() => {
    fetchData();
  }, [store, ual]);

  async function handleModeSelection(selection) {
    await anchorSetTactic(ual, Number(selection), setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  return (
    
<div className="buttonContainer w-full h-full lg:w-1/4 pt-8 px-4">
<h3 className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-center font-Montserrat text-xl font-bold text-olive mb-4">Total Stats</h3>
<table className="w-full border border-[#2F2F2E] text-center p-1">
  <thead>
    <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md">
      <th className="px-4 py-2 font-semibold">Stat</th>
      <th className="px-4 py-2 font-semibold">Value</th>
    </tr>
  </thead>
  <tbody>
    <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
      <td className="px-4 py-2">Power</td>
      <td className="px-4 py-2">{parseFloat(pvpStats.power).toFixed(3)}</td>
    </tr>
    <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
      <td className="px-4 py-2">Defense</td>
      <td className="px-4 py-2">{parseFloat(pvpStats.defense).toFixed(3)}</td>
    </tr>
    <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
      <td className="px-4 py-2">HP</td>
      <td className="px-4 py-2">{parseFloat(pvpStats.hp).toFixed(3)}</td>
    </tr>
    <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
      <td className="px-4 py-2">Accuracy</td>
      <td className="px-4 py-2">{parseFloat(pvpStats.accuracy).toFixed(3)}</td>
    </tr>
    <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
      <td className="px-4 py-2">Evasion</td>
      <td className="px-4 py-2">{parseFloat(pvpStats.evasion).toFixed(3)}</td>
    </tr>
    <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md mb-4">
      <td className="px-4 py-2">Dodge</td>
      <td className="px-4 py-2">{parseFloat(pvpStats.dodge).toFixed(3)}</td>
    </tr>
    <tr className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md">
      <td className="px-4 py-2">Crit</td>
      <td className="px-4 py-2">{parseFloat(pvpStats.crit).toFixed(3)}</td>
    </tr>
  </tbody>
</table>
<div className="flex justify-center items-center mt-4 space-x-4">
    <select
      className="w-40 bg-[#2F2F2E] text-white rounded-md px-3 py-2"
      onChange={(e) => setSelectedItem(e.target.value)}
      value={selectedItem}
    >
      <option value="" disabled>Select a tactic</option>
      <option value="0">Neutral</option>
      <option value="1">Attack</option>
      <option value="2">Defense</option>
    </select>
    <div className="relative flex items-center ml-2 group">
    <FaQuestionCircle className="text-white text-lg cursor-pointer" />
    <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 w-48 p-2 bg-gray-800 text-white text-xs rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
          Neutral: No change.<br />
          Attack: Increases attack power by 10% but lose 10% defense.<br />
          Defense: Increase defense by 10% but lose 10% power.
    </div>
  </div>

    <button
        style={{
          backgroundColor: '#2F2F2E', // Darker background color for visibility
          color: 'white',
          opacity: 0.8,
          borderRadius: '7px', // 6px rounded corners
          padding: '5px', // Space for button content
          font: '18',
          width: '10rem', // Width of 40
          fontWeight: 'bold', // Make text more prominent
          border: '2px solid black', // Optional: Add border for definition
        }}
        onClick={() => handleModeSelection(selectedItem)}
      >
        Set Tactic
      </button>
  </div>
    </div>
);
};



export default RightContent;
